import React from 'react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import { LayoutProvider, Wrapper } from '../../components/layout';
import BatteryChangeReminder from '../../components/sections/BatterChangeReminder';
import { stackStyled, useStackStyletron } from '../../theme';
import { useViewPortSize } from '../../hooks';
import { StackOnBreadcrumbs } from '../../atoms';
import { ResponsiveContainer } from '../../atoms/Containers';

export interface IBatteryChangeReminder {
  title: string;
  subtitle: string;
  description: string;
  successfulTitle: string;
  successfulSubtitle: string;
}

interface IData {
  strapiBatteryChangeReminder: IBatteryChangeReminder;
}

// GRAPHQL QUERY
const query = graphql`
  query {
    strapiBatteryChangeReminder {
      title
      successfulTitle
      successfulSubtitle
      subtitle
      description
    }
  }
`;

// STYLED COMPONENTS

const BreadCrumbsContainer = stackStyled(
  'div',
  (props: { isMobile: boolean }) => ({
    position: 'absolute',
    top: props.isMobile ? '10px' : '30px',
    left: props.isMobile ? '16px' : '10px',
    width: '100%',
    textAlign: 'left',
    display: 'flex',
  })
);

const MainWrapper = stackStyled(
  Wrapper,
  (props: { isMobile: boolean; isTablet?: boolean }) => ({
    display: 'flex',
    maxWidth: '1280px',
    width: props.isMobile ? '95%' : props.isTablet ? '90%' : '100%',
    justifyContent: 'center',
    padding: props.isMobile ? '0px 0px 0px 0px' : '50px 0px 0px 0px',
    marginLeft: 'auto',
    marginRight: 'auto',
    overflowX: 'hidden',
    textAlign: 'center',
    minHeight: 'auto',
  })
);

// COMPONENT
const BatteryChangeReminderPage: React.FC<PageProps> = (props: PageProps) => {
  // HOOKS
  const data = useStaticQuery<IData>(query);
  const [isMobile, isTablet] = useViewPortSize();
  const [css, theme] = useStackStyletron();

  // STATE

  // HANDLERS & FUNCTIONS

  // EFFECTS & CALLBACKS

  // RENDER
  const { strapiBatteryChangeReminder = {} as IBatteryChangeReminder } = data;

  return (
    <LayoutProvider seo locationSearch={props.location.search}>
      <MainWrapper isMobile={isMobile} isTablet={isTablet} noScroll>
        <BreadCrumbsContainer isMobile={isMobile}>
          <StackOnBreadcrumbs
            pages={[
              { title: 'Home', href: '/' },
              { title: 'Customer service', href: '/customer-service' },
              {
                title: 'Battery change frequency',
                href: `/customer-service/battery-change-reminder`,
              },
            ]}
          />
        </BreadCrumbsContainer>
        <ResponsiveContainer>
          <BatteryChangeReminder data={strapiBatteryChangeReminder} />
        </ResponsiveContainer>
      </MainWrapper>
    </LayoutProvider>
  );
};

export default BatteryChangeReminderPage;
