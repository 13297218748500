import React from "react";
import BatteryIcon from "../../../assets/battery-icon.svg";
import AnchorSuccess from "../../../atoms/Button/AnchorSuccess";
import { useViewPortSize } from "../../../hooks";
import { stackStyled, Typography, useStackStyletron } from "../../../theme";

const MainContainer = stackStyled("div", {
  marginTop: "150px",
  marginBottom: "14vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

interface Props {
  successData: { successfulTitle: string; successfulSubtitle: string };
}

const Success = ({ successData }: Props) => {
  // HOOKS

  const [css, theme] = useStackStyletron();
  const [isMobile, isTablet] = useViewPortSize();

  //RENDER
  const { successfulTitle = "", successfulSubtitle = "" } = successData;
  return (
    <MainContainer>
      <img
        src={BatteryIcon}
        alt={"Battery Icon"}
        className={css({ width: "120px", height: "55px", paddingBottom: isMobile ? '50px' : '65px' })}
      />
      <Typography
        $fontFamily={"Montserrat"}
        $weight={700}
        $tagType={isMobile ? 'h3Mob' : "h3"}
        $color={theme.stackOnColors.primaryGreenDark}
        $textAlign={"center"}
        $margin={0}
        $paddingBottom={isMobile ? "30px" : "20px"}
      >
        {successfulTitle}
      </Typography>
      <Typography
        $fontFamily={"Montserrat"}
        $weight={400}
        $tagType={ isMobile ? 'labelMob' : "body"}
        $color={theme.stackOnColors.secondarySilver}
        $textAlign={"center"}
        $paddingBottom={isMobile ? "38px" : "80px"}
        $margin='0'
      >
        {successfulSubtitle}
      </Typography>
      <AnchorSuccess href={'/customer-service/faqs/?select=Batteries'}  >Battery change FAQ’s</AnchorSuccess>
    </MainContainer>
  );
};

export default Success;
