import React, { useState } from "react";
import { useViewPortSize } from "../../../hooks";
import { IBatteryChangeReminder } from "../../../pages/customer-service/battery-change-reminder";
import { stackStyled, Typography, useStackStyletron } from "../../../theme";
import Form from "./Form";
import Success from "./Success";

const MainContainer = stackStyled("div", {
  padding: 0,
  width: "100%",
  height: "100%",
});

const ContentContainer = stackStyled(
  "div",
  ({ isMobile }: { isMobile: boolean }) => ({
    display: "grid",
    gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
    padding: isMobile ? "40px 0px 40px 0px" : "8vh 0 10vh 0",
    columnGap: "100px",
  })
);

const SuggestionContainer = stackStyled(
  "div",
  ({ isMobile }: { isMobile: boolean }) => ({
    paddingBottom: isMobile ? "50px" : "0px",
  })
);

const FormContainer = stackStyled("div", {});

interface Props {
  data: IBatteryChangeReminder;
}

const BatteryChangeReminder = ({ data }: Props) => {
  // HOOKS
  const [css, theme] = useStackStyletron();
  const [isMobile, isTablet] = useViewPortSize();

  // STATE

  const [formSubmited, setFormSubmited] = useState(false);

  // HANDLERS & FUNCTIONS

  function handleFormSubmited() {
    setFormSubmited(true);
  }

  // RENDER
  let {
    title = "",
    subtitle = "",
    description = "",
    successfulSubtitle,
    successfulTitle,
  } = data;

  successfulSubtitle = description

  return (
    <MainContainer>
      <Success successData={{ successfulSubtitle, successfulTitle }} />
      {/* <Typography
        $fontFamily={"Montserrat"}
        $weight={700}
        $tagType={"h3"}
        $color={theme.stackOnColors.primaryGreenDark}
        $textAlign={"center"}
      >
        {title}
      </Typography>
      {!formSubmited ? (
        <ContentContainer isMobile={isMobile}>
          <SuggestionContainer isMobile={isMobile}>
            <Typography
              $fontFamily={"Montserrat"}
              $weight={700}
              $tagType={"h4"}
              $color={theme.stackOnColors.primaryGreen}
              $textAlign={"left"}
            >
              {subtitle}
            </Typography>
            <Typography
              $fontFamily={"Montserrat"}
              $weight={400}
              $tagType={"body"}
              $color={theme.stackOnColors.primaryBlack}
              $textAlign={"left"}
            >
              {description}
            </Typography>
          </SuggestionContainer>
          <FormContainer>
            <Form onSubmit={handleFormSubmited} />
          </FormContainer>
        </ContentContainer>
      ) : (
        <Success successData={{ successfulSubtitle, successfulTitle }} />
      )} */}
    </MainContainer>
  );
};

export default BatteryChangeReminder;
